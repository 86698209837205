import { get } from "@/lib"

export function getDate(time, symbol){ 
    var y=new Date(time).getFullYear();
    var m = new Date(time).getMonth() > 9 ? Number(new Date(time).getMonth()) + 1 : '0' + Number(new Date(time).getMonth() + 1);
    var d = new Date(time).getDate() > 9 ? new Date(time).getDate() : '0' + new Date(time).getDate();
    return y+symbol+m+symbol+d; 
}

// 对手机号和密码进行校验
export function validateTel(tel) {
    let reg = /^1[3|4|5|7|8|9][0-9]{9}$/
    return reg.test(tel)
}

export function scrollToPre() {
    window.scroll(0, 0)
}

// 对邮箱进行校验
export function validateEmail(email) {
    let reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
    if(reg.test(email)) {
        return true
    }

    return false
}

// 校验密码格式
export function validatePassword(password) {
    let reg = /^(?![^a-zA-Z]+$)(?!\D+$)/
    if(reg.test(password) && password.length >= 8) {
        return true
    }

    return false
}

// 發送用戶點擊
export async function postCode(code) {
    let data = await get('collectionClick', {
        type: code
    })
    return data
}
