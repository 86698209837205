<template>
   <div>
       <common-header>{{title}}</common-header>
        <div class="container">
            <div @click="toHref(item)" class="wrapper" :class="`bg-${from} text-${from}`" v-for="(item, index) in currentList" :key="item.title">
                <span class="bx-img" v-if="from == 'bx'">
                </span>
                <span class="yp-img" :class="`yp-img-${index}`" v-if="from == 'yp'">
                </span>
                <div class="title">{{item.title}}</div>
            </div>
        </div>
   </div>
</template>
<script>
import {postCode} from '@/utils/tools'
import CommonHeader from '@/components/header.vue'
export default {
    data() {
        return {
            zx: [{
                id: 0,
                code: 'cn_zskf',
                short: 'dlzskf',
                title: '大陸診所開方',
                href: ''
            },{
                id: 1,
                code: 'cn_zjzx',
                short: 'zjzxffzx',
                title: '專家在線付費咨詢',
                href: ''
            }, {
                id: 2,
                code: 'cn_zxzx',
                short: 'zxffwz',
                title: '在線付費咨詢',
                href: 'https://mcenter.yihu.com/Ask/web/ask/firstask.html?doctorgroupid=3'
            }, {
                id: 3,
                code: 'cn_mfzx',
                short: 'zxmfzx',
                title: '在線免費咨詢',
                href: 'https://yhchmo.udesk.cn/im_client/?web_plugin_id=172771'
            }],
            zj: [{
                id: 0,
                short: 'zxmfzx',
                title: '在線免費咨詢',
                desc: '與台灣醫生一對一在線問診'
            }, {
                id: 1,
                short: 'lstd',
                title: '綠色通道',
                desc: '與大陸醫生一對一在線問診'
            }],
            yp: [{
                id: 0,
                code: 'cn_yj_kbj',
                short: 'kbj',
                title: '康佰家',
                href: ''
            }],
            // , {
            //     id: 1,
            //     short: 'yjhc',
            //     title: '又見回春',
            //     href: ''
            // }
            bx: [{
                id: 0,
                code: 'bx_picc',
                short: 'rb',
                title: '中國人保財險',
                href: ''
            }, {
                id: 1,
                code: 'bx_dadi',
                short: 'dd',
                title: '中國大地保險',
                href: 'https://msupsale.ccic-net.com.cn/#/nonCar/millionMedicalCare?shareUrlId=v669jQhiBr&marketingType=1&code=0610FnGa1ZBoeB0Tp6Ja1swkab20FnGT'
            }],
            title: '',
            currentList: [],
            from: '',
            obj: {}
        }
    },
    components: {
        CommonHeader
    },
    mounted() {
        this.obj = {
            'zx': this.zx,
            'zj': this.zj,
            'yp': this.yp,
            'bx': this.bx
        }
        this.title = this.$route.query['title']
        this.from = this.$route.query['from']
        this.currentList = this.obj[this.from ]
        console.log('this.currentList,', this.currentList)
    },
    methods: {
        toHref(item) {
            let {id, short, title} = item;
            if(item.code) {
                postCode(item.code)
            }
            switch(short) {
                case 'kbj':
                case 'yjhc':
                case 'dd':
                case 'dlzskf':
                    this.$router.push(`/container?from=${short}&title=${title}`)
                    break;
                // case 'zjzxffzx': // 專家在線付費咨詢
                //     // window.location.href = 'https://wxauth.yihu.com/apiweb/tp.html?point=mywz4qyjkgj&thirdPartyUserId=' + this.$route.query['id'] + '&appId=9001217&sourceType=2'
                //     window.location.href = 'https://ask.yihu.com/ZiXun/FindExpert/index/clientid/9001217/platformType/9001217.shtml?cityId=118&provinceId=13&cityName=%E7%A6%8F%E5%B7%9E'
                //     break;
                case 'rb':
                    this.$router.push(`/bx?from=${short}&title=${title}`)
                    break;
                case 'zjzxffzx': // 專家在線付費咨詢
                case 'zxffwz':
                    window.location.href = 'https://wxauth.yihu.com/apiweb/tp.html?point=mywz4qyjkgj&thirdPartyUserId='+ this.$route.query['id'] +'&appId=9001217&sourceType=2';
                    break;
                default:
                    window.location.href = this.obj[this.from][id]['href']
                    break;
            }
            // if(index == 0 && this.from == 'bx') {
            //     this.$router.push('/bx')
            //     return;
            // }

            // window.location.href = this.obj[this.from][index]['href']
        }
    }
}
</script>
<style lang="scss" scoped>
.container {
    padding-top: 100px;
    padding-bottom: 30px;
    box-sizing: border-box;
    background: #fff;
    min-height: 100vh;
}

.bx-img {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: url('../../assets/images/bao.png') center center no-repeat;
    background-size: 100%;
    margin-right: 6px;
}

.yp-img {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url('../../assets/images/kbj-logo.png') center center no-repeat;
    background-size: 100%;
    margin-right: 6px;

    &-0 {
        background-image: url('../../assets/images/kbj-logo.png');
    }

    &-1 {
        background-image: url('../../assets/images/yjhc-logo.png');
    }
}

.wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 305px;
    height: 90px;
    border-radius: 20px;
    margin: 0 auto;
    margin-bottom: 49px;
    padding: 0px 0 0 28px;
    box-sizing: border-box;
    text-align: left;
    position: relative;

    .recommend {
        display: block;
        position: absolute;
        right: 0;
        top: -4px;
        width: 40px;
        height: 20px;
        // background: #02C2C3;
        border-radius: 20px;
        font-size: 12px;
        color: #fff;
        text-align: center;
    }

    .title {
        font-size: 20px;
        // color: #2F9397;
    }

    .desc {
        font-size: 14px;
        // color: #2F9397;
    }
}
</style>